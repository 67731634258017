import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DocumentTable, Form, getModuleLink } from "../../../components";
import {
  docActions,
  getOrgDocuments,
  getOrgList,
  getCurrentUser,
  encyptDataObject,
} from "../../../store";

const OrgSelect = [
  {
    label: "Organisation",
    attribute: "selectedOrg",
    placeholder: "Select",
    type: "select",
    showColon: true,
    labelClassName: "",
  },
];
const DocumentColumns = [
  {
    title: "Version",
    dataIndex: "version",
    key: "version",
    width: "100px",
    className: "exo2 f9",
  },
  {
    title: "ID",
    dataIndex: "doc_id",
    key: "doc_id",
    width: "100px",
    className: "exo2 f9 text-center",
    sort: true,
  },
  {
    title: "Document",
    dataIndex: "name",
    key: "name",
    width: "42%",
    render: "btn",
    search: true,
    className: "exo2 f9",
    variant: "lite",
    color: "#0033CC",
  },
  {
    title: "Department",
    dataIndex: "department_name",
    key: "department_name",
    width: "10%",
    search: true,
    className: "exo2 f9 text-center",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    width: "11%",
    render: "color-code",
    sort: true,
    className: "exo2 f9",
  },
  {
    title: "Classification",
    dataIndex: "classification",
    key: "clasification",
    width: "12%",
    render: "color-code",
    className: "exo2 f9",
  },
  {
    title: "Type",
    dataIndex: "doc_type",
    key: "doc_type",
    width: "10%",
    sort: true,
    className: "exo2 f9 caps",
  },
];
const PAGE_SIZE = 15;
export const AllOrgDocumentsScreen = (props) => {
  const dispatch = useDispatch();
  const [selectedOrg, setSelectedOrg] = useState("");
  const documents = useSelector(getOrgDocuments);
  const orgs = useSelector(getOrgList);
  const currentUser = useSelector(getCurrentUser);
  useEffect(() => {
    dispatch(docActions.fetchOrgs());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (selectedOrg && selectedOrg.id) {
      dispatch(docActions.setOrgDocuments(null));
      dispatch(docActions.fetchOrgDocuments({ org_id: selectedOrg.id }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrg]);
  const getDocuments = () => {
    return documents;
  };
  const handleValueChange = (e) => {
    setSelectedOrg(e.target.value);
  };
  const handleClickDocument = (doc) => {
    let path = getModuleLink("documenter", currentUser);
    // path = path.replace(
    //   "https://documenter.luciance.com",
    //   "http://localhost:3003"
    // );
    const redirect = encyptDataObject({ path: `/D/A/${doc.guid}` });
    path += `&redirect=${redirect}`;
    const popup = window.open(
      path,
      "popup",
      "width=600,height=600,resizable=no,scrollbars=no,toolbar=no,menubar=no,location=no,status=no"
    );
  };
  const getColumns = () => {
    const cols = DocumentColumns.map((_) => ({ ..._ }));
    cols[2].onClick = handleClickDocument;
    return cols;
  };
  return (
    <div className="col w-100 h-100 o-hide screen-pad">
      <div className="row docs-header h-btn v-start">
        <Form
          fieldClass="w-300pix"
          Fields={OrgSelect}
          getOptions={() => orgs}
          onChange={handleValueChange}
          formData={{ selectedOrg }}
        />
      </div>
      <div className="col f-rest doc">
        <DocumentTable
          noSelection
          Columns={getColumns()}
          rows={getDocuments()}
          pageSize={PAGE_SIZE}
          emptyMessage={selectedOrg ? "No Documents" : "Select Organisation"}
        />
      </div>
    </div>
  );
};
