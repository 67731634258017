import { createSlice } from "@reduxjs/toolkit";
import { ReducerRegistry } from "../app";

const initialState = {
  departments: [],
  documentTypes: [],
  documentClassification: [],
  orgList: null,
  statusList: [],
};

const commonSlice = createSlice({
  name: "common",
  initialState: initialState,
  reducers: {
    fetchDepartments: () => {},
    fetchDocumentTypes: () => {},
    fetchDocumentStatus: () => {},
    fetchDocumentClassifications: () => {},
    fetchOrgs: () => {},
    fetchOrgSuccess: (state, action) => {
      state.orgList = action.payload;
    },
    setDepartments: (state, action) => {
      state.departments = action.payload;
    },
    setDocumentTypes: (state, action) => {
      state.documentTypes = action.payload;
    },
    setDocumentClassifications: (state, action) => {
      state.documentClassification = action.payload;
    },
    setDocStatus: (state, action) => {
      state.statusList = action.payload;
    },
  },
});
export const commonActions = commonSlice.actions;

ReducerRegistry.register("common", commonSlice.reducer);
