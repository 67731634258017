import { encode } from "base-64";
import "./saga";
import moment from "moment";
export * from "./slice";
const getCommonState = (state) => state.common;

export const getDepartments = (state) => {
  const _state = getCommonState(state);
  return _state.departments;
};
export const getDocClassifications = (state) => {
  const _state = getCommonState(state);
  return _state.documentClassification;
};
export const getDocTypes = (state) => {
  const _state = getCommonState(state);
  return _state.documentTypes;
};
export const getDocStatus = (state) => {
  const _state = getCommonState(state);
  return _state.statusList;
};
export const getAllOrgs = (state) => {
  const _state = getCommonState(state);
  return _state.orgList;
};
export const dateSorter = (attribute, itemA, itemB) => {
  return moment(itemB[attribute]).diff(itemA[attribute]);
};
export const encyptDataObject = (iData) => {
  let parsed = JSON.stringify(iData);
  parsed = encode(parsed);
  return parsed;
};
