import "./saga";
export * from "./slice";

const getDocState = (state) => state.doc;

export const SECTION_SIZE = 850;

export const STATUS = {
  APPROVED: "approved",
  ACTIVE: "active",
  DELETED: "deleted",
  ARCHIVED: "archived",
  DRAFT: "draft",
};
export const getStatusList = (state) => {
  const _state = getDocState(state);
  return _state.StatusList;
};
export const getVariableSources = (state) => {
  const _state = getDocState(state);
  return _state.VariableSources;
};
export const getAllVariables = (state) => {
  const _state = getDocState(state);
  return _state.variables;
};
export const getContentBlueprints = (status, state) => {
  const _state = getDocState(state);
  return status === "active" ? _state.activeContents : _state.draftContents;
};
export const getArchives = (status, state) => {
  const _state = getDocState(state);
  return status === "content"
    ? _state.archiveContents
    : _state.archivePresentations;
};
export const getContentBlueprint = (blueprintId, state) => {
  const _state = getDocState(state),
    finder = (_) => _.id === Number(blueprintId);
  let blueprint = _state.activeContents.find(finder);
  if (!blueprint) {
    blueprint = _state.draftContents.find(finder);
  }
  if (!blueprint) {
    blueprint = _state.archiveContents.find(finder);
  }
  return blueprint;
};
export const getShowBlueprint = (state) => {
  const _state = getDocState(state);
  return _state.showBlueprint;
};
export const isArchived = (blueprint) =>
  blueprint &&
  (blueprint.status === "archive" || blueprint.status === "archived");

export const getBlueprintHistory = (state) => {
  const _state = getDocState(state);
  return _state.currentHistory;
};
export const getNewDocumentId = (state) => {
  const _state = getDocState(state);
  return _state.newDocumentId;
};
export const getPresentations = (state) => {
  const _state = getDocState(state);
  return _state.presentations;
};
export const isDraft = (blueprint) => {
  return (
    blueprint &&
    (blueprint.status === "draft" || blueprint.status === "outline")
  );
};
export const isApproved = (blueprint) => {
  return blueprint && blueprint.status === STATUS.APPROVED;
};
export const isActive = (blueprint) => {
  return blueprint && blueprint.status === STATUS.ACTIVE;
};
export const getContentChangeHistory = (state) => {
  const _state = getDocState(state);
  return _state.changeHistory;
};
export const getContentAuditHistory = (state) => {
  const _state = getDocState(state);
  return _state.auditHistory;
};
export const getDeleteStatus = (state) => {
  const _state = getDocState(state);
  return _state.deleteInProgress;
};
export const getActivePresentation = (state) => {
  const _state = getDocState(state);
  return _state.activePresentation;
};
export const getOrgDocuments = (state) => {
  const _state = getDocState(state);
  return _state.orgDocuments || [];
};
export const getCurrentOrgDoc = (state) => {
  const _state = getDocState(state);
  return _state.currentOrgDocument;
};
export const getOrgList = (state) => {
  const _state = getDocState(state);
  return _state.orgList;
};
