import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useSearchParams, useNavigate } from "react-router-dom";
import { userActions, getLogInStatus } from "../../store";
import { OvalLoading } from "../../components";
// import { KEY_NM_API } from "neumetric-components";
const KEY_NM_API = "NM-API-Key";
export const LoginScreen = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loginStatus = useSelector(getLogInStatus);
  const [searchParams] = useSearchParams();
  const sessionId = searchParams.get("sessionToken");
  useEffect(() => {
    document.title = "Controller | Sandbox";
    if (!sessionId) {
      navigate("/");
    }
    dispatch(userActions.validateToken({ token: sessionId }));
  }, []);
  useEffect(() => {
    if (loginStatus) {
      dispatch(userActions.logInSuccessClear());
      navigate("/");
    }
  }, [loginStatus]);
  return (
    <div className="col w-100 h-100">
      <OvalLoading message="Logging...." messageClassName="f6" />
    </div>
  );
};
